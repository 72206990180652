import React, { useEffect, useRef, useState } from 'react';
import styles from './VerticalImageSlider.module.css';

const VerticalImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
    const startY = useRef(null);

    const touchThreshold = 40;

    const handleTouchStart = (e) => {
        startY.current = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
        if (startY.current !== null) {
            const deltaY = e.changedTouches[0].clientY - startY.current;

            if (deltaY > touchThreshold && currentIndex > 0) {
                setCurrentIndex((prevIndex) => prevIndex - 1);
            } else if (deltaY < -touchThreshold && currentIndex < images.length - 1) {
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }

            startY.current = null;
        }
    };

    useEffect(() => {
        setCurrentIndex(0);
    }, [images]);

    useEffect(() => {
        if (containerRef.current) {
            const slides = containerRef.current.children;
            const targetSlide = slides[currentIndex];
            if (targetSlide) {
                containerRef.current.style.transform = `translateY(-${targetSlide.offsetTop}px)`;
            }
        }
    }, [currentIndex]);

    return (
        <div
            className={styles.slider}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onScroll={(e) => { containerRef.current.style.transform = `translateY(-${0}px)`; }}
            style={{
                cursor: 'grab',
                overflowY: 'auto',
            }}
        >
            <div
                className={styles.slidesContainer}
                ref={containerRef}
                style={{ transition: 'transform 0.3s ease-in-out' }}
            >
                {images.map((image, index) => (
                    <div key={index} className={styles.slide}>
                        <img src={image.url} alt={`Slide ${index + 1}`} className={styles.image} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VerticalImageSlider;
