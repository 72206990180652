import React, { useState } from 'react';
import styles from './ProductView.module.css';

function Description({ description, name }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 128;

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded); // Переключение состояния
    };

    const formatDescription = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index !== text.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    // Форматированное неполное описание
    const shortenedDescription = () => {
        const trimmedDescription = description.substring(0, maxLength) + "...";
        return formatDescription(trimmedDescription);
    };

    return (
        <div>
            <p className={styles.description}>
                <span>{name} — </span>
                {isExpanded
                    ? formatDescription(description)
                    : shortenedDescription()}
                <button onClick={toggleExpanded} className={styles.toggleBtnDes}>
                    {isExpanded ? 'Скрыть' : 'Читать дальше'}
                </button>
            </p>
        </div>
    );
}

export default Description;