
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import ProductLabelComponent from '../../ProductLabelComponent';
import styles from './ProductsComponent.module.css';

const ProductsComponent = ({ products, storeID, small = false }) => {
    const nav = useNavigate();

    const formatPriceWithThousandSeparator = price => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const extractDigits = inputString => inputString.replace(/\D/g, '');

    const getCharacteristicValue = (characteristics, name) => characteristics.find(characteristic => characteristic.name === name)?.value;

    const containerRef = useRef(null);
    const handleWheel = (e) => {
        if (e.deltaY !== 0) {
            if (e.deltaY > 0) {
                containerRef.current.scrollLeft += e.deltaY;
            }
        }
    };

    return (
        <div ref={containerRef} className={`${small ? styles.smallProducts : styles.products}`} onWheel={handleWheel}>
            {products.map(product => {
                const flatBaseInfoArray = { flatSize: getCharacteristicValue(product.characteristics, "Площадь, кв. м"), flatData: getCharacteristicValue(product.characteristics, "Дата сдачи"), flatComp: getCharacteristicValue(product.characteristics, "Количество комнат") };
                const characteristicsArray = [
                    { label: 'Кв-л', value: getCharacteristicValue(product.characteristics, "Номер квартала") },
                    { label: 'д.', value: getCharacteristicValue(product.characteristics, "Дом") },
                    { label: 'с.', value: getCharacteristicValue(product.characteristics, "Секция") },
                    { label: 'эт.', value: getCharacteristicValue(product.characteristics, "Этаж") },
                    { label: '№', value: extractDigits(product.name) },
                ].filter(char => char.value);

                const displayFields = characteristicsArray
                    .map(char => `${char.label} ${char.value}`)
                    .join(' / ');

                return (
                    <div className={styles.productCard} key={uuid()} onClick={() => { nav(`/store/${storeID}/product/${product.product}`); small && window.location.reload(); }}>
                        <div className={styles.flatComp}>{flatBaseInfoArray.flatComp === "1" || flatBaseInfoArray.flatComp === "2" || flatBaseInfoArray.flatComp === "3" ? flatBaseInfoArray.flatComp === "1" ? flatBaseInfoArray.flatComp + " комната" : flatBaseInfoArray.flatComp + " комнаты" : flatBaseInfoArray.flatComp}</div>
                        <div className={styles.firstLine}>
                            {product.photos.length > 0 ? <img src={product.photos[0]?.url} alt='Нет фото' className={styles.previewImg} /> : <div className={styles.previewImg}><span>Нет фото</span></div>}
                        </div>
                        <div className={styles.secondLine}>
                            <div className={styles.flatInfo}>
                                <span>{displayFields}</span>
                            </div>
                            <div className={styles.price}>
                                {product.total_price === 1 ? (
                                    <div className={styles.priceLeft}>
                                        <span className={styles.originalPrice}>Бесплатно</span>
                                    </div>
                                ) : product.price !== product.total_price ? (
                                    <>
                                        <span className={styles.flatSize}>
                                            {getCharacteristicValue(product.characteristics, 'Площадь, кв. м') ? `${getCharacteristicValue(product.characteristics, 'Площадь, кв. м')} м²` : ''}
                                        </span>
                                        <div className={styles.priceLeft}>
                                            <span className={styles.discountPrice}>{formatPriceWithThousandSeparator(product.price)} ₽</span>
                                            <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)} ₽</span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {flatBaseInfoArray.flatSize && <span className={styles.flatSize}>{flatBaseInfoArray.flatSize + " м²"}</span>}
                                        <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)} ₽</span>
                                    </>
                                )}
                            </div>
                            {
                                flatBaseInfoArray.flatData && <span className={styles.flatData}>
                                    <span>Срок сдачи:</span>
                                    <span>{flatBaseInfoArray.flatData}</span>
                                </span>
                            }
                        </div>
                        {product.labels.length > 0 && <div className={styles.labels}>{product.labels.map(label => (<ProductLabelComponent key={uuid()} labelStyle={label} />))}</div>}
                    </div>
                );
            })}
        </div>
    );
};

export default ProductsComponent;