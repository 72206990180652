import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import "../../../Constants.css";
import { useTelegram } from '../../useTelegram';
import ImageSlider from './ImageSlider';
import styles from './ProductView.module.css';
import Description from './Description';

const ProductView = ({ data, filteredCombos, handleOptionChange, selectedOptions, isAddedToCart, storeID, handleAddToCart, provider, copyToClipboard }) => {
    const { user } = useTelegram();
    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();
    const [cartItemData, setCartItemData] = useState({ quantity: 0, cart_item_id: null, step: 1, min: 1 });

    const handleScroll = () => {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    };

    const incrementQuantity = () => {
        if (cartItemData.cart_item_id) {
            if (cartItemData?.quantity < filteredCombos[0].stock) {
                api.increaseCartItemQuantity(cartItemData.cart_item_id).then((r) => {
                    if (r?.data) {
                        setCartItemData(prev => ({ ...prev, quantity: prev.quantity + prev.step }));
                    }
                });
            }
        }
    };

    const decrementQuantity = () => {
        if (cartItemData.cart_item_id) {
            if (cartItemData?.quantity - cartItemData?.step >= cartItemData?.min) {
                api.decreaseCartItemQuantity(cartItemData.cart_item_id).then(r => {
                    if (r?.data) {
                        setCartItemData(prev => ({ ...prev, quantity: prev.quantity - prev.step }));
                    }
                });
            } else {
                api.removeCartItem(cartItemData.cart_item_id).then((r) => {
                    setCartItemData(prev => ({ ...prev, quantity: 0 }));
                })
            }
        }

    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (filteredCombos) {
            api.getItemCartCount(user.id.toString(), storeID, filteredCombos[0]?.id).then((r) => {
                if (r?.data) {
                    setCartItemData(r.data);
                }
            });
        }

    }, [filteredCombos, isAddedToCart]);

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={styles.container} ref={scrollContainerRef}>
            {filteredCombos ? (
                <>
                    {filteredCombos ? (
                        <ImageSlider images={filteredCombos[0].photos} />
                    ) : (
                        <div className={styles.emptyImg}> <span>Нет фото</span> </div>
                    )}
                    <div className={styles.productInfo}>
                        <p className={styles.price}>
                            {filteredCombos[0]?.total_price !== filteredCombos[0]?.price && (
                                <span className={styles.oldPrice}>{formatPriceWithThousandSeparator(filteredCombos[0]?.price)}</span>
                            )}
                            {formatPriceWithThousandSeparator(filteredCombos[0]?.total_price)}
                        </p>

                        <div className={styles.options}>
                            {data.variants['var1'].options[0].length > 0 & data.variants['var1'].name !== '' ?
                                <div className={styles.option}>
                                    <p>{data.variants['var1'].name + ":"}</p>
                                    <div className={styles.optionValues}>
                                        {data.variants['var1'].options[0].map((optionValue, index) => (
                                            <button
                                                key={index}
                                                className={`${styles.optionValue} ${selectedOptions['var1'] === optionValue ? styles.selected : ''}`}
                                                onClick={() => { handleOptionChange('var1', optionValue); }}
                                            >
                                                {optionValue}
                                            </button>
                                        ))}
                                    </div>
                                </div> : null}

                            {data.variants['var2'].options[0].length > 1 ?
                                <div className={styles.option}>
                                    <p>{data.variants['var2'].name + ":"}</p>
                                    <div className={styles.optionValues}>
                                        {data.variants['var2'].options[0].map((optionValue, index) => (
                                            <button
                                                key={index}
                                                className={`${styles.optionValue} ${selectedOptions['var2'] === optionValue ? styles.selected : ''}`}
                                                onClick={() => { handleOptionChange('var2', optionValue); }}
                                            >
                                                {optionValue}
                                            </button>
                                        ))}
                                    </div>
                                </div> : null}
                        </div>

                        <Description description={filteredCombos[0]?.description} name={filteredCombos[0]?.name} />

                        <div className={styles.characteristics}>
                            {data.characteristics.length > 0 ? (
                                <>
                                    <p>Основные характеристики</p>
                                    {data.characteristics.map((char, index) => (
                                        <div key={index}>
                                            <span key={2 * index}>{char.name + ":"}</span>
                                            <span className={styles.characteristicValue} key={2 * index + 1}>{char.value}</span>
                                        </div>
                                    ))}
                                </>
                            ) : null}
                            {provider && <>
                                <p>Информация о поставщике</p>
                                <div>
                                    <span>Поставщик:</span>
                                    <span className={styles.characteristicValue}>{provider}</span>
                                </div>
                            </>}
                        </div>


                        <div className={styles.dicrementContainer}>
                            {cartItemData.quantity === 0 ?
                                <button className={styles.addToCartButton} onClick={() => { handleAddToCart(); navigate(`/store/${storeID}/cart`); }}>
                                    Купить
                                </button>
                                :
                                <div className={styles.quantityArea}>
                                    <span className={styles.changeBtn} onClick={decrementQuantity}>-</span>
                                    <span className={styles.qSection}>{cartItemData.quantity}</span>
                                    <span className={styles.changeBtn} onClick={incrementQuantity}>+</span>
                                </div>
                            }
                            {cartItemData.quantity === 0 ?
                                <button className={styles.addToCartButton} onClick={handleAddToCart}>
                                    В корзину
                                </button>
                                :
                                <button className={styles.addToCartButton} onClick={() => { navigate(`/store/${storeID}/cart`) }}>
                                    Корзина
                                </button>
                            }
                        </div>

                        <div className={`${styles.navBtns} ${scrollPosition > 10 ? styles.activeScroll : null}`}>
                            <button className={styles.backButton} onClick={() => copyToClipboard()}></button>
                            <button className={styles.cartBtn} onClick={() => { navigate(`/store/${storeID}/cart`) }}></button>
                        </div>
                    </div>
                </>
            ) : (null)
            }

        </div>
    );
};

export default ProductView;
