import React from 'react';
import styles from './StoreInfoComponent.module.css';

const StoreInfoComponent = ({ storeInfo, divRef }) => {

    return (
        <div className={styles.storeInfo} ref={divRef}>
            <div className={styles.info}>
                <h2>{storeInfo.name}</h2>
                {/* <p>{storeInfo.description}</p> */}
            </div>
            {storeInfo.picture_url && <div className={styles.logo}><img src={storeInfo.picture_url} alt="Лого" /></div>}
        </div>
    );
};

export default StoreInfoComponent;
