import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import { Link, useNavigate } from 'react-router-dom';
import "../../../Constants.css";
import InfoModal from '../../InfoModal';
import styles from './CheckoutView.module.css';

const CheckoutView = ({ storeID, orderFields, handleSubmit, name, setName, phone, setPhone, email, setEmail, comment, setComment, showModal, showTitle, showText, closeInfoModal }) => {

    const navigate = useNavigate();

    const tg = window.Telegram.WebApp;

    const stopActionEnter = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
        }
    };

    const handlePhoneNumberChange = (event) => {
        const inputPhoneNumber = event.target.value.replace(/\D/g, '');
        setPhone(inputPhoneNumber);
    };

    return (
        <div className={styles.container}>
            <form onSubmit={handleSubmit}>
                <div className={styles.formBaseSection}>
                    <h2>Заполните форму для связи</h2>
                    {orderFields.name && <input
                        type="text"
                        id="name"
                        onKeyDown={stopActionEnter}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder='Ваше имя'
                        required
                    />}
                    {true && <InputMask
                        type="tel"
                        id="phone"
                        onKeyDown={stopActionEnter}
                        value={phone}
                        mask="+7 (999) 999-99-99"
                        placeholder="Ваш номер телефона"
                        onChange={(e) => handlePhoneNumberChange(e)}
                        required
                    />}
                    {orderFields.comment && <input
                        type="text"
                        id="comment"
                        onKeyDown={stopActionEnter}
                        value={comment}
                        onChange={(e) => { if (e.target.value.length < 128) setComment(e.target.value) }}
                        placeholder='Комментарий'
                    />}
                </div>

                <div className={styles.paymentSection}>
                    <div className={styles.agreeText}>
                        <span>Оставляя заявку, Вы даете согласие на <Link to={'https://southerncity.ru/upload/policy.pdf'} target='_blank'>обработку персональных данных</Link> </span>
                    </div>
                </div>

                {showModal ? null : (
                    <button type="submit" className={styles.orderBtn}>
                        Оставить заявку
                    </button>
                )}
            </form>
            <InfoModal showModal={showModal} onClose={() => { closeInfoModal ? tg.close() : navigate(`/store/${storeID}`) }} title={showTitle} text={showText} />
        </div>
    );
};

export default CheckoutView;
