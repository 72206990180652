import React, { useRef, useCallback } from 'react';
import styles from './BannersComponent.module.css';

const BannersComponent = ({ banners, divRef }) => {
    const containerRef = useRef(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const handleMouseDown = useCallback((e) => {
        e.preventDefault(); // Отключаем действие по умолчанию
        isDragging.current = true;
        startX.current = e.pageX - containerRef.current.offsetLeft;
        scrollLeft.current = containerRef.current.scrollLeft;
        containerRef.current.style.cursor = 'grabbing'; // Изменяем курсор при перетаскивании
    }, []);

    const handleMouseLeave = useCallback(() => {
        isDragging.current = false;
        containerRef.current.style.cursor = 'grab'; // Возвращаем курсор
    }, []);

    const handleMouseUp = useCallback(() => {
        isDragging.current = false;
        containerRef.current.style.cursor = 'grab'; // Возвращаем курсор
    }, []);

    const handleMouseMove = useCallback((e) => {
        if (!isDragging.current) return; // Если не перетаскиваем, выходим
        e.preventDefault(); // Отключаем действие по умолчанию
        const x = e.pageX - containerRef.current.offsetLeft; // Позиция курсора
        const walk = (x - startX.current); // Увеличиваем скорость прокрутки
        containerRef.current.scrollLeft = scrollLeft.current - walk; // Прокрутка контейнера
    }, []);

    const handleDragStart = useCallback((e) => {
        e.preventDefault(); // Отключаем действие по умолчанию для drag
    }, []);

    return (
        <>
            {
                banners.length === 0 ? null :
                    <div
                        className={styles.banners}
                        ref={(el) => {
                            divRef.current = el; // сохраняем переданный divRef
                            containerRef.current = el; // сохраняем реф для контейнера
                        }}
                        onMouseDown={handleMouseDown}

                        onMouseLeave={handleMouseLeave}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        style={{ cursor: 'grab' }} // стиль курсора
                    >
                        {
                            banners.map((banner) => {
                                const uniqueKey = crypto.randomUUID(); // Генерация уникального ключа
                                if (banner.text === "") {
                                    return (
                                        <Banner key={uniqueKey} imageUrl={banner.img} altText={banner.text} onDragStart={handleDragStart} />
                                    );
                                }
                                return (
                                    <Promotion key={uniqueKey} imageUrl={banner.img} altText={banner.text} onDragStart={handleDragStart} />
                                );
                            })
                        }
                    </div>
            }
        </>
    );
};

const Banner = ({ imageUrl, altText, onDragStart }) => {
    return (
        <div className={styles.banner}>
            <img src={imageUrl} alt={altText} onDragStart={onDragStart} />
        </div>
    );
};

function Promotion({ imageUrl, altText, onDragStart }) {
    return (
        <div className={styles.promo}>
            <img src={imageUrl} alt="" onDragStart={onDragStart} />
            <p>{altText}</p>
        </div>
    );
}

export default BannersComponent;