import styles from "./Pagination.module.css";

const PaginationV2 = ({ totalPages, currentPage, onPageChange, light = false, template }) => {
    if (totalPages < 2) {
        return <></>;
    }

    return (
        <div className={`${styles.pagination} ${template === 4 && styles.darkMargin}`}>
            {
                currentPage + 1 <= totalPages &&
                <span className={`${styles.nextButton} ${light && styles.nextButtonLight}`} onClick={() => onPageChange(currentPage + 1)}>
                    Загрузить ещё
                </span>
            }
        </div >
    );
};

export default PaginationV2;
