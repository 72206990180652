import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import CompacityComponent from "./CompacityComponent";
import FilterComponent from "./FilterComponent";
import styles from "./PopupFilterComponent.module.css";
import SliderComponent from "./SliderComponent";

function PopupFilterComponent({ trigerForPoup, filters, handleFilter }) {
    const overlayStyle = {
        background: 'rgba(0, 0, 0, 0.7)'
    };

    const [selectedFlats, setSelectedFlats] = useState([]);
    const [areaRangeS, setAreaRangeS] = useState([1, 7]);
    const [areaRangeF, setAreaRangeF] = useState([1, 17]);
    const [selectedViews, setSelectedViews] = useState([]);
    const [selectedQuarters, setSelectedQuarters] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);

    const filterData = () => {
        const filterQuery = {
            rooms: selectedFlats.join(';'),
            area: areaRangeS.join(';'),
            floor: areaRangeF.join(';'),
            view: selectedViews.join(';'),
            quarter: selectedQuarters.join(';'),
            c_date: selectedDates.join(';')
        };
        handleFilter(filterQuery);
    };

    const clearFilterData = () => {
        setSelectedFlats([]);
        setAreaRangeS([Math.round(filters.area.characteristic_values.min_value), Math.round(filters.area.characteristic_values.max_value)]);
        setAreaRangeF([Math.round(filters.floor.characteristic_values.min_value), Math.round(filters.floor.characteristic_values.max_value)]);
        setSelectedViews([]);
    };

    useEffect(() => {
        if (filters) {
            clearFilterData();
        }
    }, [filters]);

    return (
        <Popup trigger={trigerForPoup} modal={true} overlayStyle={overlayStyle}>
            {close => <div className={styles.container}>
                {filters?.rooms && <CompacityComponent title={"Комнатность"} flats={filters.rooms["characteristic_values"]} selected={selectedFlats} setSelected={setSelectedFlats} />}
                {filters?.quarter && <FilterComponent title={"Квартал"} options={filters.quarter["characteristic_values"]} selectedOptions={selectedQuarters} setSelectedOptions={setSelectedQuarters} />}
                {filters?.area && <SliderComponent areaRange={areaRangeS} setAreaRange={setAreaRangeS} minRange={Math.round(filters.area.characteristic_values.min_value)} maxRange={Math.round(filters.area.characteristic_values.max_value)} />}
                {filters?.floor && < SliderComponent areaRange={areaRangeF} setAreaRange={setAreaRangeF} metrics={"этаж"} minRange={Math.round(filters.floor.characteristic_values.min_value)} maxRange={Math.round(filters.floor.characteristic_values.max_value)} />}
                {filters?.view && <FilterComponent title={"Вид из окна"} options={filters.view["characteristic_values"]} selectedOptions={selectedViews} setSelectedOptions={setSelectedViews} />}
                {filters?.c_date && <FilterComponent title={"Срок сдачи"} options={filters.c_date["characteristic_values"]} selectedOptions={selectedDates} setSelectedOptions={setSelectedDates} />}
                <button className={styles.close} onClick={close}></button>
                <button className={styles.showbtn} onClick={() => { filterData(); close(); }}>Показать</button>
                <button className={styles.buttonClean} onClick={() => { clearFilterData(); handleFilter(null); close(); }}>
                    Сбросить фильтры
                </button>
            </div>}
        </Popup>
    )
}
export default PopupFilterComponent;