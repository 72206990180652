import React from "react";
import styles from "./CompacityComponent.module.css";

function CompacityComponent({ flats, title, selected, setSelected }) {
    // Фильтруем данные для отображения кнопок
    const studio = flats.find(flat => flat.toLowerCase() === "студия");
    const numbers = flats.filter(flat => /^[1-4]$/.test(flat));
    const euroFormats = flats.filter(flat => flat.toLowerCase().includes("евро"));

    const handleClick = (type) => {
        if (type === "C" && studio) {
            // Обработка студии
            setSelected(selected.includes(studio)
                ? selected.filter(item => item !== studio)
                : [...selected, studio]);
        } else if (type === "Евро" && euroFormats.length) {
            // Обработка евроформатов
            const allEuroSelected = euroFormats.every(format => selected.includes(format));
            setSelected(allEuroSelected
                ? selected.filter(item => !euroFormats.includes(item))
                : [...selected, ...euroFormats.filter(format => !selected.includes(format))]);
        } else if (/^[1-4]$/.test(type)) {
            // Обработка числовых форматов
            setSelected(selected.includes(type)
                ? selected.filter(item => item !== type)
                : [...selected, type]);
        }
    };

    return (
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            <div className={styles.buttonsContainer}>
                {studio && (
                    <button
                        key="C"
                        className={`${styles.button} ${selected.includes(studio) ? styles.selected : ""}`}
                        onClick={() => handleClick("C")}
                    >
                        С
                    </button>
                )}
                {numbers.map((num) => (
                    <button
                        key={num}
                        className={`${styles.button} ${selected.includes(num) ? styles.selected : ""}`}
                        onClick={() => handleClick(num)}
                    >
                        {num}
                    </button>
                ))}
                {euroFormats.length > 0 && (
                    <button
                        key="Евро"
                        className={`${styles.button} ${euroFormats.every(format => selected.includes(format)) ? styles.selected : ""
                            }`}
                        onClick={() => handleClick("Евро")}
                    >
                        Евро
                    </button>
                )}
            </div>
        </div>
    );
}

export default CompacityComponent;
