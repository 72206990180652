import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "../../../Constants.css";
import BannersComponent from './BannersComponent';
import styles from './CatalogPage.module.css';
import CategoryComponent from './CategoryComponent';
import ProductsComponent from './ProductsComponent';
import StoreInfoComponent from './StoreInfoComponent';

const CatalogPage = ({ data, setCartCount, hanlderForCategories, hanlderForSubCategories, cartData, setCartData, preSelectedCategory, preSelectedSubCategory, searchComponent }) => {
    const { storeID } = useParams();
    const [scrollDistance, setScrollDistance] = useState(0);
    const bannersDiv = useRef(null);
    const storeDiv = useRef(null);
    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const nav = useNavigate();
    const tg = window.Telegram.WebApp;

    const handleScroll = () => {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    };

    useEffect(() => {
        tg.setHeaderColor('#172D55');
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let res = 0;
        if (bannersDiv.current?.offsetHeight) {
            res += bannersDiv.current.offsetHeight;
        }
        if (storeDiv.current?.offsetHeight) {
            res += storeDiv.current.offsetHeight;
        }
        setScrollDistance(res);
    }, [bannersDiv, storeDiv]);

    return (
        <div className={styles.container} ref={scrollContainerRef}>
            <BannersComponent banners={data.banners} divRef={bannersDiv} />
            <StoreInfoComponent storeInfo={data.storeInfo} divRef={storeDiv} />
            {searchComponent}
            {data.categories.length > 1 && <CategoryComponent categories={data.categories} handler={hanlderForCategories} scrollDistance={scrollDistance} scrollPosition={scrollPosition} preSelectedCategory={preSelectedCategory} isCategory={true} />}
            {(preSelectedCategory !== null && data.subcategories.length > 1) && <CategoryComponent categories={data.subcategories} handler={hanlderForSubCategories} scrollDistance={scrollDistance} scrollPosition={scrollPosition} preSelectedCategory={preSelectedSubCategory} isCategory={data.categories.length < 2} />}
            <div className={styles.productsContainer}>
                <ProductsComponent products={data.products} storeID={storeID} scrollDistance={scrollDistance} scrollPosition={scrollPosition} setCartCount={setCartCount} cartData={cartData} setCartData={setCartData} />
            </div>
            {cartData.length > 0 && (
                <div className={styles.orderButtonArea}>
                    <button className={styles.orderBtn} onClick={() => { nav(`/store/${storeID}/cart`) }}>
                        В корзину
                    </button>
                </div>
            )
            }
        </div >
    );
};

export default CatalogPage;
