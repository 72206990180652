import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../../../Constants.css";
import styles from './ProductView.module.css';
import VerticalImageSlider from './VerticalImageSlider';
import catalogApi from "../../../pages/StoreBasePage/api/api";
import ProductsComponent from '../CatalogView/ProductsComponent';

const ProductView = ({ data, filteredCombos, handleOptionChange, selectedOptions, isAddedToCart, storeID, handleAddToCart, provider, copyToClipboard }) => {

    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();

    const handleScroll = () => {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    };

    useEffect(() => {
        // const filterQuery = {
        //     area: [].join(';'),
        // };
        // catalogApi.getStoreProducts(storeID, 1, null, null, null, null).then((response) => { });

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    useEffect(() => {
        if (data?.characteristics) {
            const area = data.characteristics.filter(el => el.name === "Площадь, кв. м");
            if (area.length > 0) {
                const centrArea = Number(area[0].value);
                const filterQuery = {
                    area: `${centrArea - 5};${centrArea + 5}`,
                };
                catalogApi.getStoreProducts(storeID, 1, null, null, null, filterQuery).then((response) => { setSuggestions(response?.data?.products ? response.data.products.filter(el => el.name !== data.items.name) : []) });
            }
        }
    }, [data]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [filteredCombos]);

    return (
        <div className={styles.container} ref={scrollContainerRef}>
            {filteredCombos && (
                <>
                    {filteredCombos ? (
                        <VerticalImageSlider images={filteredCombos[0].photos} />
                    ) : (
                        <div className={styles.emptyImg}> <span>Нет фото</span> </div>
                    )}
                    <div className={styles.productInfo}>
                        <p className={styles.description}>
                            <span>{filteredCombos[0]?.name} — </span>
                            {filteredCombos[0]?.description.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    {index !== filteredCombos[0]?.description.split('\n').length - 1 && <br />}
                                </React.Fragment>
                            ))}
                        </p>

                        <div className={styles.options}>
                            {data.variants['var1'].options[0].length > 0 & data.variants['var1'].name !== '' ?
                                <div className={styles.option}>
                                    <p>{data.variants['var1'].name + ":"}</p>
                                    <div className={styles.optionValues}>
                                        {data.variants['var1'].options[0].map((optionValue, index) => (
                                            <button
                                                key={index}
                                                className={`${styles.optionValue} ${selectedOptions['var1'] === optionValue ? styles.selected : ''}`}
                                                onClick={() => { handleOptionChange('var1', optionValue); }}
                                            >
                                                {optionValue}
                                            </button>
                                        ))}
                                    </div>
                                </div> : null}

                            {data.variants['var2'].options[0].length > 1 ?
                                <div className={styles.option}>
                                    <p>{data.variants['var2'].name + ":"}</p>
                                    <div className={styles.optionValues}>
                                        {data.variants['var2'].options[0].map((optionValue, index) => (
                                            <button
                                                key={index}
                                                className={`${styles.optionValue} ${selectedOptions['var2'] === optionValue ? styles.selected : ''}`}
                                                onClick={() => { handleOptionChange('var2', optionValue); }}
                                            >
                                                {optionValue}
                                            </button>
                                        ))}
                                    </div>
                                </div> : null}
                        </div>

                        <div className={styles.price}>
                            <span className={styles.oldPrice}>Цена: {formatPriceWithThousandSeparator(filteredCombos[0]?.price)}</span>
                            <p>Цена по акции*: {filteredCombos[0]?.total_price === 1 ? "0" : formatPriceWithThousandSeparator(filteredCombos[0]?.price * 0.94)}</p>
                            <span className={styles.discountInfo}>
                                *При оплате наличными. Размер Вашей персональной скидки уточняйте у менеджера.
                            </span>
                        </div>

                        <div className={styles.characteristics}>
                            {data.characteristics.length > 0 ? (
                                <>
                                    <p>Основные параметры</p>
                                    {data.characteristics.map((char, index) => (
                                        <div key={index}>
                                            <span key={2 * index}>{char.name + ":"}</span>
                                            <span className={styles.characteristicValue} key={2 * index + 1}>{char.value}</span>
                                        </div>
                                    ))}
                                </>
                            ) : null}
                            {provider && <>
                                <p>Информация о поставщике</p>
                                <div>
                                    <span>Поставщик:</span>
                                    <span className={styles.characteristicValue}>{provider}</span>
                                </div>
                            </>}
                        </div>


                        <button
                            className={styles.addToCartButton}
                            onClick={() => { handleAddToCart(); navigate(`/store/${storeID}/order`); }}
                        >
                            Оставить заявку
                        </button>

                        <div className={styles.suggestContainer}>
                            <span className={styles.suggestText}>Похожие варианты</span>
                            <ProductsComponent products={suggestions} storeID={storeID} small={true} />
                        </div>

                        <div className={styles.callMeContainer}>
                            <Link to={"tel:+78462150514"}>+7 (846) 215-05-14</Link>
                            <span onClick={() => { navigate(`/store/${storeID}/order`) }}>Заказать звонок</span>
                        </div>

                        <div className={`${styles.navBtns}`}>
                            <button className={styles.cartBtn} onClick={() => { navigate(`/store/${storeID}/order`) }}></button>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
};

export default ProductView;
