import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styles from './SliderComponent.css';

const SliderComponent = ({ areaRange, setAreaRange, minRange, maxRange, metrics = "м²" }) => {
    const [editingMin, setEditingMin] = useState(false);
    const [editingMax, setEditingMax] = useState(false);
    const [tempMin, setTempMin] = useState(areaRange[0]);
    const [tempMax, setTempMax] = useState(areaRange[1]);

    const handleSliderChange = (values) => {
        setAreaRange(values);
    };

    const handleMinClick = () => {
        setEditingMin(true);
        setTempMin(areaRange[0]);
    };

    const handleMaxClick = () => {
        setEditingMax(true);
        setTempMax(areaRange[1]);
    };

    const handleMinChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setTempMin(value);
        }
    };

    const handleMaxChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setTempMax(value);
        }
    };

    const handleMinBlur = () => {
        const value = Number(tempMin);
        if (value >= minRange && value <= areaRange[1]) {
            setAreaRange([value, areaRange[1]]);
        } else {
            setTempMin(areaRange[0]);
        }
        setEditingMin(false);
    };

    const handleMaxBlur = () => {
        const value = Number(tempMax);
        if (value <= maxRange && value >= areaRange[0]) {
            setAreaRange([areaRange[0], value]);
        } else {
            setTempMax(areaRange[1]);
        }
        setEditingMax(false);
    };

    return (
        <div className="sliderContainer">
            <div className="values">
                {editingMin ? (
                    <div className={styles.sliderInputCont} style={{ display: "flex", alignItems: "center" }}>
                        <input
                            type="text"
                            value={tempMin}
                            onChange={handleMinChange}
                            onBlur={handleMinBlur}
                            autoFocus
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                outline: 'none',
                                color: 'black',
                                fontWeight: 600,
                            }}
                        />
                        <span>{metrics}</span>
                    </div>
                ) : (
                    <span onClick={handleMinClick}> {areaRange[0]} {metrics}</span>
                )}
                {editingMax ? (
                    <div className={styles.sliderInputCont} style={{ display: "flex", alignItems: "center" }}>
                        <input
                            type="text"
                            value={tempMax}
                            onChange={handleMaxChange}
                            onBlur={handleMaxBlur}
                            autoFocus
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                outline: 'none',
                                color: 'black',
                                fontWeight: 600,
                            }}
                        />

                        <span>{metrics}</span>
                    </div>
                ) : (
                    <span onClick={handleMaxClick}> {areaRange[1]} {metrics}</span>
                )}
            </div>
            <Slider
                value={areaRange}
                onChange={handleSliderChange}
                min={minRange}
                max={maxRange}
                step={1}
                className="reactSlider"
                range
                trackStyle={[{ backgroundColor: '#ef7d00', height: 4 }]}
                handleStyle={[
                    { borderColor: '#ef7d00', height: 20, width: 20,  marginTop: -8, backgroundColor: '#ffffff', opacity: 1 },
                    { borderColor: '#ef7d00', height: 20, width: 20,  marginTop: -8, backgroundColor: '#ffffff', opacity: 1 }
                ]}
                railStyle={{ backgroundColor: '#d3d3d3', height: 4 }}
            />
        </div>
    );
};

export default SliderComponent;