import React from 'react';
import styles from './StoreInfoComponent.module.css';

const StoreInfoComponent = ({ storeInfo, divRef, handleAddToCart }) => {

    return (
        <div className={styles.storeInfo} ref={divRef}>
            {storeInfo.picture_url && <div className={styles.logo}><img src={storeInfo.picture_url} alt="Лого" /></div>}
            <button className={styles.callMe} onClick={handleAddToCart}></button>
        </div>
    );
};

export default StoreInfoComponent;
