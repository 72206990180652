const tg = window.Telegram.WebApp;

export function useTelegram() {
  return {
    tg,
    user: tg.initDataUnsafe?.user,
    // user: { id: 506401893 },
    queryId: tg.initDataUnsafe?.query_id,
  }
}
