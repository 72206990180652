import React, { useState, useRef, useEffect } from 'react';
import styles from "./FilterComponent.module.css";
import CustomCheckBox from "./CustomCheckBoxComponent";

const FilterComponent = ({ title, options, selectedOptions, setSelectedOptions }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((o) => o !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const getButtonText = () => {
        if (selectedOptions.length === 0) {
            return 'Любой';
        } else if (selectedOptions.length === 1) {
            return selectedOptions[0];
        } else if (selectedOptions.length === 2) {
            return selectedOptions.join(', ');
        } else {
            return `${selectedOptions.length} выбрано`;
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Проверка, кликнут ли вне dropdown
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Добавляем обработчик события при монтировании компонента
        document.addEventListener('mousedown', handleClickOutside);
        
        // Убираем обработчик события при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.filterComponent} ref={dropdownRef}>
            <span className={styles.title}>{title}</span>
            <div className={styles.dropdown}>
                <button onClick={toggleDropdown} className={`${styles.trigger} ${isOpen && styles.triggerActive}`}>
                    {getButtonText()}
                </button>
                {isOpen && (
                    <div className={styles.dropdownMenu}>
                        {options.map((option, index) => (
                            <div key={index} className={styles.option}>
                                <label>
                                    <CustomCheckBox
                                        checked={selectedOptions.includes(option)}
                                        onChange={() => handleCheckboxChange(option)}
                                    />
                                    <span>{option}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FilterComponent;
