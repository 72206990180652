import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../api/api';
import ProductViewAstroknight from '../../components/Astroknight/ProductView/ProductView';
import ProductViewBlue from '../../components/Blue/ProductView/ProductView';
import ProductViewDrevo from '../../components/Drevo/ProductView/ProductView';
import ProductViewBox from '../../components/Boxberry/ProductView/ProductView';
import ProductViewDark from '../../components/dark/ProductView/ProductView';
import ProductViewLid from '../../components/bijobs/ProductView/ProductView';
import ProductViewKonkordia from '../../components/Konkordia/ProductView/ProductView';
import ProductViewList from '../../components/List/ProductView/ProductView';
import Notification from '../../components/Notification';
import useNotification from '../../components/useNotification';
import { useTelegram } from '../../components/useTelegram';
import ProductViewWhite from '../../components/White/ProductView/ProductView';

const ProductPage = () => {
    const navigate = useNavigate();
    const { notification, notify } = useNotification();
    const { user } = useTelegram();
    const [productData, setProductData] = useState({});
    const [filteredCombos, setFilteredCombos] = useState(null);
    const [template, setTemplate] = useState("0");
    const { storeID, productID } = useParams();
    const [selectedOptions, setSelectedOptions] = useState({});
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [btnColor, setbtnColor] = useState('#000000');
    const [provider, setProvider] = useState(null);

    const handleOptionChange = (variantName, optionValue) => {
        let newOptions = { var1: selectedOptions.var1, var2: selectedOptions.var2 };
        newOptions[variantName] = optionValue;
        const filteredCombos = productData.items.filter(combo => {
            for (const [variantName, optionValue] of Object.entries(newOptions)) {
                if (combo[`var${variantName.slice(-1)}_value`] !== optionValue) {
                    return false;
                }
            }
            return true;
        });

        if (filteredCombos.length > 0) {
            setSelectedOptions(prevOptions => ({
                ...prevOptions,
                [variantName]: optionValue
            }));
        } else {
            const oppositeVar = variantName === 'var1' ? 'var2' : 'var1';

            const matchingCombo = productData.items.find(combo =>
                combo[`var${variantName.slice(-1)}_value`] === optionValue
            );

            if (matchingCombo) {
                const oppositeValue = matchingCombo[`var${oppositeVar.slice(-1)}_value`];
                newOptions[oppositeVar] = oppositeValue;

                setSelectedOptions(prevOptions => ({
                    ...prevOptions,
                    [variantName]: optionValue,
                    [oppositeVar]: oppositeValue
                }));
            }
        }
    };

    const handleAddToCart = () => {
        const step = Number.isInteger(parseInt(productData.add_to_cart_step, 10))
            ? parseInt(productData.add_to_cart_step, 10)
            : 1;
        const quantity = Number.isInteger(parseInt(productData.min_order_value, 10))
            ? parseInt(productData.min_order_value, 10)
            : 1;
        const cartItem = {
            user_id: user.id.toString(),
            store_id: storeID,
            quantity: quantity,
            add_to_cart_step: step,
            min_order_value: quantity,
            combo_id: filteredCombos[0]?.id,
            product_id: filteredCombos[0]?.product,
            name: filteredCombos[0]?.name,
            var1_name: productData?.variants['var1'].name,
            var1_value: filteredCombos[0]?.var1_value,
            var2_name: productData?.variants['var2'].name,
            var2_value: filteredCombos[0]?.var2_value,
            price: filteredCombos[0]?.price,
            total_price: filteredCombos[0]?.total_price,
            photo: filteredCombos[0]?.photos[0]?.url,
            weight: filteredCombos[0]?.weight,
            depth: filteredCombos[0]?.depth,
            height: filteredCombos[0]?.height,
            width: filteredCombos[0]?.width,
            item_id: filteredCombos[0]?.vendor_code,
            tax: filteredCombos[0]?.tax,
        }

        api.addCartItem(cartItem).then((r) => {
            if (r.status !== 204) {
                setIsAddedToCart(true);
            }
        });
    };

    const sortOptions = (options) => {
        let notIntCounter = 0;

        if (options.length < 2) {
            return options;
        }

        const optionsTemp = options;

        options = options.map(option => {
            if (typeof option === "string" && !isNaN(option) && !option.includes('.')) {
                if (parseInt(Number(option)) === option) {
                    return parseFloat(option);
                } else {
                    notIntCounter += 1;
                    return option;
                }
            }
            notIntCounter += 1;
            return option;
        });

        if (options.length - notIntCounter > 0) {
            return optionsTemp;
        }

        if (options.every(option => typeof option === "number")) {
            return options.sort((a, b) => a - b).map(item => { return String(item) });
        }

        // XXS, M, MO, S, SO, L, LO, XL, XXL, 3XL, 4XL, 5XL
        const ORDER = ['XXS', 'S', 'SO', 'M', 'MO', 'L', 'LO', 'XL', 'XXL', '3XL', '4XL', '5XL'];
        if (options.every(option => typeof option === "string")) {
            return options.sort((a, b) => {
                a = a.toUpperCase();
                b = b.toUpperCase();

                let nra = parseInt(a);
                let nrb = parseInt(b);

                if ((ORDER.indexOf(a) !== -1)) nra = NaN;
                if ((ORDER.indexOf(b) !== -1)) nrb = NaN;

                if (nrb === 0) return 1;
                if (nra & !nrb || nra === 0) return -1;
                if (!nra && nrb) return 1;
                if (nra && nrb) {
                    if (nra === nrb) {
                        return (a.substr(('' + nra).length)).localeCompare((a.substr(('' + nra).length)));
                    } else {
                        return nra - nrb;
                    }
                } else {
                    return ORDER.indexOf(a) - ORDER.indexOf(b);
                }
            });
        }

        return options;
    };

    useEffect(() => {

        tg.BackButton.onClick(() => navigate(`/store/${storeID}`));
        tg.BackButton.show();

        var template_code = localStorage.getItem('template_code');
        if (template !== undefined) {
            setTemplate(template_code);
            if (template_code === "1") {
                setbtnColor('#ed1651');
            }
        }

        api.getProduct(productID).then((response) => {
            const responseData = response.data;
            template_code = String(response.data?.template_code);
            localStorage.setItem('template_code', template_code);
            setTemplate(template_code);
            if (template_code === "1") {
                setbtnColor('#ed1651');
            }

            if (responseData.items.length > 1) {
                const responseDataSorted = {
                    ...responseData,
                    variants: {
                        var1: {
                            ...responseData.variants.var1,
                            options: [sortOptions(responseData.variants.var1.options)],
                        },
                        var2: {
                            ...responseData.variants.var2,
                            options: [sortOptions(responseData.variants.var2.options)],
                        },
                    },
                };

                setProductData(responseDataSorted);

                var defaultCombo;

                if (responseDataSorted.items.length > 1) {
                    defaultCombo = responseDataSorted.items[0];
                }

                const defaultOptions = {
                    var1: defaultCombo.var1_value,
                    var2: defaultCombo.var2_value
                };

                setSelectedOptions(defaultOptions);
            } else {
                const responseDataSorted = {
                    ...responseData,
                    items: {
                        ...responseData.items,
                        var1: "",
                        var2: "",
                    },
                    variants: {
                        var1: {
                            name: '',
                            options: [''],
                        },
                        var2: {
                            name: '',
                            options: [''],
                        },
                    },
                };
                setProductData(responseDataSorted);
                const defaultOptions = {
                    var1: '',
                    var2: ''
                };
                setSelectedOptions(defaultOptions);
            }
        }).catch((error) => {
            navigate(`/store/${storeID}`);
        });

        api.getShopID(storeID).then(tinkoff_r => { setProvider(tinkoff_r.data.name) });
    }, [productID, storeID]);

    useEffect(() => {
        if (productData.items) {
            if (productData.is_variative) {
                if (productData.items.length > 1) {
                    const filteredCombos = productData.items.filter(combo => {
                        for (const [variantName, optionValue] of Object.entries(selectedOptions)) {
                            if (combo[`var${variantName.slice(-1)}_value`] !== optionValue) {
                                return false;
                            }
                        }
                        return true;
                    });
                    setFilteredCombos(filteredCombos);
                } else {
                    setFilteredCombos(productData.items);
                }
            } else {
                setFilteredCombos([productData.items])
            }
            setIsAddedToCart(false);
        }
    }, [productData.items, selectedOptions]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(productData.tg_link)
            .then(() => {
                notify('Ссылка скопирована');
            })
            .catch((err) => {
                notify('Ссылка не скопирована');
            });
    };

    // Main button
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        if (isAddedToCart === false) {
            tg.MainButton.setParams({ is_active: false, is_visible: false });
        }
    }, [btnColor, isAddedToCart]);

    return (
        <>
            {
                template === "0" ? (
                    <ProductViewWhite data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : template === "1" ? (
                    <ProductViewBox data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : template === "2" ? (
                    <ProductViewList data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : template === "3" ? (
                    <ProductViewBlue data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : template === "4" ? (
                    <ProductViewDark data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : template === "99" ? (
                    <ProductViewAstroknight data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : template === "7" ? (
                    <ProductViewLid data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : template === "77" ? (
                    <ProductViewDrevo data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : template === "8" ? (
                    <ProductViewKonkordia data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                ) : (
                    <ProductViewWhite data={productData} setData={setProductData} filteredCombos={filteredCombos} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} isAddedToCart={isAddedToCart} storeID={storeID} handleAddToCart={handleAddToCart} provider={provider} copyToClipboard={copyToClipboard} />
                )}
            {notification && (
                <Notification
                    message={notification}
                    onClose={() => notify(null)}
                />
            )}
        </>
    );
};

export default ProductPage;