import React, { useEffect, useState } from 'react';
import arrow from "../icons8-шеврон-влево-30.png";
import arrowRight from "../icons8-шеврон-вправо-30.png";
import styles from "./Category.module.css";

const CategoryComponent = ({ categories, handler, preSelectedCategory, isCategory }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [uniqueCategories, setInfo] = useState(["Все", ...new Set(categories)]);
  const [allCategories] = useState(categories);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  const handleViewClick = () => {
    handler({ target: { textContent: selectedCategory } });
    setModalOpen(false);
  }

  useEffect(() => {
    var tempUniqueCategories;
    if (isCategory) {
      tempUniqueCategories = ["Все", ...new Set(categories)];
    } else {
      tempUniqueCategories = [...new Set(categories)];
      console.log(tempUniqueCategories);
    }
    if (tempUniqueCategories.length > 2) {
      setInfo(tempUniqueCategories);
    } else {
      setSelectedCategory("Все");
      setInfo([...new Set(categories)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, allCategories]);

  useEffect(() => {
    if (isCategory) {
      setSelectedCategory(preSelectedCategory === null ? 'Все' : preSelectedCategory);
    } else {
      setSelectedCategory(preSelectedCategory === null ? 'Все' : preSelectedCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <div className={styles.catContainer}>
      <div className={styles.openModalButton} onClick={() => setModalOpen(true)}>
        <div>
          <span>{isCategory === true ? "Категория" : "Подкатегория"}</span>
          <span>{preSelectedCategory === null ? "Все" : preSelectedCategory}</span>
        </div>
        <img src={arrowRight} alt=''></img>
      </div>
      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>

            {uniqueCategories.length > 1 ? uniqueCategories.map((category, index) => (
              <div className={styles.categoryItem} onClick={() => handleClick(category)}>

                <span
                  key={index}
                >
                  {category}
                </span>
                <span className={selectedCategory === category ? styles.circleActive : styles.circle}></span>

              </div>

            )) : null}
          </div>
          <button onClick={handleViewClick} className={styles.viewButton}>
            Посмотреть
          </button>
          <div className={styles.backBtn} onClick={() => setModalOpen(false)}>
            <img src={arrow} alt=''></img>
            <span>Назад</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default CategoryComponent;