import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../../Constants.css";
import ImageSlider from './ImageSlider';
import styles from './ProductView.module.css';
import Description from './Description';

const ProductView = ({ data, filteredCombos, handleOptionChange, selectedOptions, isAddedToCart, storeID, handleAddToCart, provider, copyToClipboard }) => {

    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();

    const handleScroll = () => {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={styles.container} ref={scrollContainerRef}>
            {filteredCombos ? (
                <>
                    {filteredCombos ? (
                        <ImageSlider images={filteredCombos[0].photos} />
                    ) : (
                        <div className={styles.emptyImg}> <span>Нет фото</span> </div>
                    )}
                    <div className={styles.productInfo}>
                        <Description description={filteredCombos[0]?.description} name={filteredCombos[0]?.name} />

                        <div className={styles.options}>
                            {data.variants['var1'].options[0].length > 0 & data.variants['var1'].name !== '' ?
                                <div className={styles.option}>
                                    <p>{data.variants['var1'].name + ":"}</p>
                                    <div className={styles.optionValues}>
                                        {data.variants['var1'].options[0].map((optionValue, index) => (
                                            <button
                                                key={index}
                                                className={`${styles.optionValue} ${selectedOptions['var1'] === optionValue ? styles.selected : ''}`}
                                                onClick={() => { handleOptionChange('var1', optionValue); }}
                                            >
                                                {optionValue}
                                            </button>
                                        ))}
                                    </div>
                                </div> : null}

                            {data.variants['var2'].options[0].length > 1 ?
                                <div className={styles.option}>
                                    <p>{data.variants['var2'].name + ":"}</p>
                                    <div className={styles.optionValues}>
                                        {data.variants['var2'].options[0].map((optionValue, index) => (
                                            <button
                                                key={index}
                                                className={`${styles.optionValue} ${selectedOptions['var2'] === optionValue ? styles.selected : ''}`}
                                                onClick={() => { handleOptionChange('var2', optionValue); }}
                                            >
                                                {optionValue}
                                            </button>
                                        ))}
                                    </div>
                                </div> : null}
                        </div>

                        <div className={styles.price}>
                            {filteredCombos[0]?.total_price !== filteredCombos[0]?.price && (
                                <span className={styles.oldPrice}>Цена: {formatPriceWithThousandSeparator(filteredCombos[0]?.price)}</span>
                            )}
                            <p>Цена для Вас: {filteredCombos[0]?.total_price === 1 ? "0" : formatPriceWithThousandSeparator(filteredCombos[0]?.total_price)}</p>
                        </div>

                        <div className={styles.characteristics}>
                            {data.characteristics.length > 0 ? (
                                <>
                                    <p>Основные характеристики</p>
                                    {data.characteristics.map((char, index) => (
                                        <div key={index}>
                                            <span key={2 * index}>{char.name + ":"}</span>
                                            <span className={styles.characteristicValue} key={2 * index + 1}>{char.value}</span>
                                        </div>
                                    ))}
                                </>
                            ) : null}
                            {provider && <>
                                <p>Информация о поставщике</p>
                                <div>
                                    <span>Поставщик:</span>
                                    <span className={styles.characteristicValue}>{provider}</span>
                                </div>
                            </>}
                        </div>


                        <button
                            className={styles.addToCartButton}
                            onClick={() => { handleAddToCart(); navigate(`/store/${storeID}/order`); }}
                        >
                            Оставить заявку
                        </button>
                        <div className={`${styles.navBtns}`}>
                            <button className={styles.cartBtn} onClick={() => { navigate(`/store/${storeID}/order`) }}></button>
                        </div>
                    </div>
                </>
            ) : (null)
            }

        </div>
    );
};

export default ProductView;
