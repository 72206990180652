import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api/api';
import "../../../Constants.css";
import { useTelegram } from '../../useTelegram';
import BannersComponent from './BannersComponent';
import styles from './CatalogPage.module.css';
// import CategoryComponent from './CategoryComponent';
import PopupFilterComponent from './Filters/PopupFilterComponent';
import ProductsComponent from './ProductsComponent';
import SearchComponent from './SearchComponent';
import StoreInfoComponent from './StoreInfoComponent';

const CatalogPage = ({ data, setCartCount, hanlderForCategories, hanlderForSubCategories, cartData, setCartData, preSelectedCategory, preSelectedSubCategory, numProducts, doSearch, doClear, totalPages, currentPage, onPageChange, handleFilter }) => {

    const { storeID } = useParams();
    const { user } = useTelegram();
    const navigate = useNavigate();
    const [scrollDistance, setScrollDistance] = useState(0);
    const bannersDiv = useRef(null);
    const storeDiv = useRef(null);

    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleAddToCart = () => {
        if (data.products.length >= 1) {
            const formProduct = data.products[0];
            const cartItem = {
                user_id: user.id.toString(),
                store_id: storeID,
                quantity: 1,
                add_to_cart_step: 1,
                min_order_value: 1,
                combo_id: formProduct?.items?.id,
                product_id: formProduct?.product,
                name: formProduct?.items?.name,
                var1_name: '',
                var1_value: '',
                var2_name: '',
                var2_value: '',
                price: formProduct?.items?.price,
                total_price: formProduct?.items?.total_price,
                photo: formProduct?.items?.photos[0]?.url,
                weight: formProduct?.items?.weight,
                depth: formProduct?.items?.depth,
                height: formProduct?.items?.height,
                width: formProduct?.items?.width,
                item_id: formProduct?.items?.vendor_code,
                tax: formProduct?.items?.tax,
            }

            api.addCartItem(cartItem).then((r) => {
                if (r.status !== 204) {
                    navigate(`/store/${storeID}/order`);
                }
            });
        } else {
            navigate(`/store/${storeID}/order`);
        }
    };

    useEffect(() => {
        let res = 0;
        if (bannersDiv.current?.offsetHeight) {
            res += bannersDiv.current.offsetHeight;
        }
        if (storeDiv.current?.offsetHeight) {
            res += storeDiv.current.offsetHeight;
        }
        setScrollDistance(res);
    }, [bannersDiv, storeDiv]);

    return (
        <div className={styles.container} ref={scrollContainerRef}>
            <BannersComponent banners={data.banners} divRef={bannersDiv} />
            <StoreInfoComponent storeInfo={data.storeInfo} divRef={storeDiv} handleAddToCart={handleAddToCart} />
            <SearchComponent numProducts={numProducts} doSearch={doSearch} doClear={doClear} />
            <div className={styles.filtersArea}>
                <span>Подобрать квартиру по заданным параметрам:</span>
                <PopupFilterComponent trigerForPoup={<span className={styles.btn}>Фильтры</span>} filters={data?.filters} handleFilter={handleFilter} />
            </div>
            <div className={styles.productsContainer}>
                <ProductsComponent products={data.products} storeID={storeID} scrollDistance={scrollDistance} scrollPosition={scrollPosition} setCartCount={setCartCount} cartData={cartData} setCartData={setCartData} />
            </div>
            {data.products.length === 0 && <div className={styles.emptyCatalog}><span>Не нашлось подходящих вариантов</span></div>}
            <div className={styles.pagination}>
                {
                    currentPage + 1 <= totalPages &&
                    <span className={styles.nextButton} onClick={() => onPageChange(currentPage + 1)}>
                        Показать ещё
                    </span>
                }
            </div >
            <div className={styles.callMe} onClick={handleAddToCart}>
                <span>Заказать звонок</span>
            </div>
        </div>
    );
};

export default CatalogPage;
