import React, { useState } from "react";
import styles from "./SearchComponent.module.css";

export default function SearchComponent({ doSearch, doClear, numProducts }) {
    const [menuFlag, setMenuFlag] = useState(false);
    const [extraclass, setExtraClass] = useState('');
    const [searchStatus, setSeacrchStatus] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handlerOpen = () => {
        setMenuFlag(true);
        setExtraClass('');
    };

    const handlerClose = () => {
        setExtraClass(styles.searchContainerDis);
        setTimeout(setMenuFlag, 400, false);
    };

    const handlerSearch = () => {
        if (searchValue.length > 2) {
            setSeacrchStatus(true);
            doSearch(searchValue);
        }
    };

    const handlerInput = (e) => {
        setSearchValue(e.target.value);
    };

    const handlerInputKey = (e) => {
        if (e.key === 'Enter') {
            if (searchValue === '') {
                return;
            }

            if (searchValue.length > 2) {
                setSeacrchStatus(true);
                doSearch(searchValue);
            }
        }
    };

    const handlerClearInput = () => {
        setSearchValue('');
        setSeacrchStatus(false);
        handlerClose();
        doClear();
    };

    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>
                <input type="text" className={styles.searchContainerInput} placeholder="Что ищете?" onChange={handlerInput} value={searchValue} onKeyDown={handlerInputKey}></input>
                <div className={styles.searchContainerContentArrow} onClick={handlerSearch}></div>
            </div>
            <div className={styles.searchResults}>
                {searchStatus ? <><div className={styles.searchContainerText}>По вашему запросу нашлось {numProducts ? numProducts : 0} позиций</div>
                    <span className={styles.searchContainerReset} onClick={handlerClearInput}>сбросить поиск</span></> : null}
            </div>

        </div>
    )
}