import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useTelegram } from "./components/useTelegram";
import CartPage from "./pages/CartPage/CartPage";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import GamePage from "./pages/GamePage/GamePage";
import MarketPlace from "./pages/MarketPlace_v2/MarketPlace";
// import MarketPlace from "./pages/Marketplace/MarketPlace";
import CommonStorePage from "./pages/CommonStorePage/CommonStorePage";
import ProductPage from "./pages/ProductPage/ProductPage";
import StoreBasePage from "./pages/StoreBasePage/StoreBasePage";

const App = () => {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
    tg.setHeaderColor('#ffffff');
    tg.isClosingConfirmationEnabled = true;
    tg.expand();
    tg.disableVerticalSwipes();

    function minimizeHandler(object) {
      if (!this.isExpanded) {
        this.expand();
      }
    };

    tg.onEvent('viewportChanged', minimizeHandler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="*" element={<MarketPlace />} />
        <Route path="/market" element={<MarketPlace />}></Route>
        <Route path="/direct-store" element={<CommonStorePage />}></Route>
        <Route path="/play" element={<GamePage />}></Route>
        <Route path="/store/:storeID" element={<StoreBasePage />}></Route>
        <Route path="/store/:storeID/product/:productID" element={<ProductPage />}></Route>
        <Route path="/store/:storeID/cart" element={<CartPage />}></Route>
        <Route path="/store/:storeID/order" element={<CheckoutPage />}></Route>
      </Routes>
    </Router>
  )
}

const root = createRoot(document.getElementById("root"))
root.render(<App />)
